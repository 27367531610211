import React, { useState, useEffect } from 'react'
import { LoginWrapper, LoginForm, Header, Links, ForgotPassword, RedirectReason } from './styled'
import { Input, Button, Modal } from 'components'
import { useHistory } from 'react-router-dom'
import firebase from 'firebase/app'
import { useStoreon } from 'storeon/react'
import { requestPasswordReset } from 'apis'
import { colors } from 'constant'
//old login method
const Login = ({ handleGoToSignup }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const [resetLoading, setResetLoading] = useState(false)
  const [loginLoading, setLoginLoading] = useState(false)
  const { user, loading, isMobile, redirect, dispatch } = useStoreon('user', 'loading', 'redirect', 'isMobile')
  const history = useHistory()

  useEffect(() => {
    if (user && !loading) {
      let path = '/'

      if (redirect) {
        const { pathname, search } = redirect
        path = `${pathname}${search}`
      }

      history.push(path)
    }
  }, [user, loading, history, redirect])

  const onSubmit = async e => {
    e.preventDefault()

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
    } catch (error) {
      const { code } = error

      if (code === 'auth/invalid-email') {
        setEmailError('Please enter a valid email')
      } else {
        dispatch('setNotification', { message: 'The email and password provided do not match.', timer: 5000, color: colors.c2 })
      }
    }
  }

  const onResetSubmit = async e => {
    e.preventDefault()
    setResetLoading(true)
    await requestPasswordReset(email)
    setShowPasswordReset(false)
    dispatch('setNotification', { message: 'A password reset link has been sent if this email exists in our system', timer: 8000 })
    setResetLoading(false)
  }

  return (
    <LoginWrapper isMobile={isMobile}>
      <Header reason={redirect?.reason}>Login</Header>
      <p>Welcome back!</p>
      {
        redirect?.reason && <RedirectReason>{redirect.reason}</RedirectReason>
      }
      <LoginForm onSubmit={onSubmit}>
        <Input
          error={emailError}
          label='Email'
          value={email}
          onChange={value => {
            setEmail(value)
            setEmailError(null)
          }}
        />
        <Input
          label='Password'
          type='password'
          value={password}
          onChange={value => setPassword(value)}
        />
        <Button
          type='submit'
          disabled={!email || !password}
          onClick={() => {setLoginLoading(true)}}
        >
          { !loginLoading ?
            `Login`
            : `Loggin in...`
          }
        </Button>
      </LoginForm>
      <Links>
        <a onClick={handleGoToSignup}>Don't have an account? <span>Register</span></a>
        <a onClick={() => setShowPasswordReset(true)}><span>Forgot your password?</span></a>
      </Links>
      <Modal
        open={showPasswordReset}
        onClose={() => setShowPasswordReset(false)}
      >
        <ForgotPassword isMobile={isMobile} onSubmit={onResetSubmit}>
          <h1>Forgot your password?</h1>
          <p>Enter your email below and we'll send you a password reset link</p>
          <Input
            label='Email'
            value={email}
            onChange={value => setEmail(value)}
          />
          <Button
            loading={resetLoading}
            disabled={!email}
            type='submit'
          >Reset Password</Button>
          <a onClick={() => setShowPasswordReset(false)}>Cancel</a>
        </ForgotPassword>
      </Modal>
    </LoginWrapper>
  )
}

export default Login
