import {
  Wrapper,
  AutocompleteWrapper,
  SearchWrapper,
  SearchContent,
  TagInputWrapper,
  AutocompleteResults,
  SectionWrapper,
  LoadWrapper,
  ResultItems,
  ResultItemWrapper,
  NoResultWrapper,
  MobileResultItem,
  MobileHeader,
  ResultItemThumbnail,
  ResultItemDetails,
  MobileResultWrapper
} from './styled'
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import {
  useOnClickOutside,
  genImagePath,
  formatDateP,
  updateQuery,
  track
} from 'utils'
import { Loader, TextInput, SearchInput } from 'components'
import { useLazyQuery, gql, useQuery } from '@apollo/client'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { useStoreon } from 'storeon/react'
import { useHistory, useLocation } from 'react-router-dom'
import Fuse from 'fuse.js'

const GET_ALL_EVENTS = gql`
  query getAllEvents($where: events_bool_exp) {
    events(where: $where) {
      id
      name
      slug
      location
    }
  }
`

const GET_ALL_VENUES = gql`
  query getAllVenues {
    venues {
      id
      slug
      name
    }
  }
`

const GET_EVENT_RESULTS = gql`
  query getEventResults($limit: Int!, $where: events_bool_exp = {}) {
    events(limit: $limit, where: $where) {
      id
      name
      slug
      location
      start_date
      end_date
      event_images {
        id
        name
      }
      cover_image_id
      event_tags {
        id
        tag_id
      }
    }
  }
`

const GET_VENUE_RESULTS = gql`
  query getVenueResults($limit: Int!, $where: venues_bool_exp = {}) {
    venues(limit: $limit, where: $where) {
      id
      slug
      name
      venue_image {
        name
      }
      venue_tags {
        id
        tag_id
      }
    }
  }
`

const SearchBox = (props) => {
  const [showResults, setShowResults] = useState(false)
  const ref = useRef()
  const [searchTerm, setSearchTerm] = useState('')
  const history = useHistory()

  const { pathname } = useLocation()
  const { isMobile, dispatch } = useStoreon('isMobile')

  // const [closestMatch, setClosestMatch] = useState('')
  const now = format(new Date(), 'P')
  const { data: allEventsData } = useQuery(GET_ALL_EVENTS, {
    variables: {
      where: {
        end_date: {
          _gte: now
        }
      }
    }
  })
  const { data: allVenuesData } = useQuery(GET_ALL_VENUES)
  const [getEventResults, { loading: eventLoading, data: eventData }] =
    useLazyQuery(GET_EVENT_RESULTS)
  const [getVenueResults, { loading: venueLoading, data: venueData }] =
    useLazyQuery(GET_VENUE_RESULTS)

  useOnClickOutside(ref, () => setShowResults(false))

  const { onChange, tags: tagProp = [], searchValue } = props

  const handleSearch = useCallback(() => {
    track('Search Events', {
      keyword: searchTerm
    })

    dispatch('setShowSearchModal', false)

    if (pathname.includes('/events')) {
      updateQuery({
        history,
        newParams: { q: searchTerm }
      })
    } else {
      updateQuery({
        history,
        newParams: { q: searchTerm },
        route: '/events'
      })
    }
  }, [searchTerm])

  useEffect(() => {
    setSearchTerm(searchValue)
  }, [searchValue])

  useEffect(() => {
    if (searchTerm && allEventsData && allVenuesData) {
      const allEvents = allEventsData.events
      const allVenues = allVenuesData.venues

      const fuseOptions = {
        includeScore: true,
        threshold: 0.3, // Adjust the threshold for typo tolerance
        keys: ['name']
      }

      const fuseEvents = new Fuse(allEvents, fuseOptions)
      const fuseVenues = new Fuse(allVenues, fuseOptions)

      const eventResults = fuseEvents.search(searchTerm)
      const venueResults = fuseVenues.search(searchTerm)

      const searchTerms = [
        ...eventResults.map((result) => result.item.name),
        ...venueResults.map((result) => result.item.name)
      ]

      getEventResults({
        variables: {
          limit: 50,
          where: {
            _or: searchTerms.map((term) => ({
              name: {
                _ilike: `%${term}%`
              }
            })),
            end_date: {
              _gte: now
            }
          }
        }
      })

      getVenueResults({
        variables: {
          limit: 50,
          where: {
            _or: searchTerms.map((term) => ({
              name: {
                _ilike: `%${term}%`
              }
            }))
          }
        }
      })
    }
  }, [searchTerm, allEventsData, allVenuesData])

  const handleSearchInput = (e) => {
    e.stopPropagation()
    const value = e.target.value

    if (value === '') {
      setShowResults(false)
    } else {
      setShowResults(true)
    }
    setSearchTerm(value)
  }

  const handleClear = (e) => {
    e.stopPropagation()
    setShowResults(false)
    setSearchTerm('')
  }

  // console.log('event: ', eventData)
  // console.log('event:', eventData)
  // console.log('venue: ', venueData)

  function TruncatedText({ text, limit }) {
    const [truncatedText, setTruncatedText] = useState(text)

    const truncateText = () => {
      if (text?.length > limit) {
        setTruncatedText(text?.slice(0, limit) + '...')
      }
    }

    useEffect(() => {
      truncateText()
    }, [])

    return <React.Fragment>{truncatedText}</React.Fragment>
  }

  const sendToEvent = (slug) => {
    gtag('event', 'event_clicked', {
      event_clicked: 1
    })

    dispatch('setShowSearchModal', false)

    history.push(`/events/${slug}`)
  }

  const sendToVenue = (slug) => {
    gtag('venue', 'venue_clicked', {
      venue_clicked: 1
    })

    dispatch('setShowSearchModal', false)

    history.push(`/venue/${slug}`)
  }

  const renderResults = () => {
    if (eventLoading || venueLoading) {
      return (
        <LoadWrapper>
          <Loader />
        </LoadWrapper>
      )
    }

    return (
      <AutocompleteResults>
        {((eventData?.events?.length > 0 || venueData?.venues?.length > 0) && (
          <ResultItemWrapper>
            <MobileHeader>Events</MobileHeader>
            {eventData?.events.length > 0 ? (
              eventData?.events.map((event, idx) => {
                const {
                  cover_image_id: coverImageId,
                  event_images: eventImages,
                  start_date: startDate,
                  end_date: endDate,
                  id,
                  name,
                  location,
                  slug
                } = event
                const allImages = eventImages
                const coverImage =
                  allImages.find((i) => i.id === coverImageId) ||
                  allImages[0] ||
                  {}
                const imageUrl = genImagePath({
                  ownerType: 1,
                  ownerId: id,
                  name: coverImage.name
                })

                return (
                  <MobileResultItem key={idx}>
                    <MobileResultWrapper onClick={() => sendToEvent(slug)}>
                      <ResultItemThumbnail
                        $imageUrl={`${imageUrl}?height=300&auto=compression`}
                      />
                      <ResultItemDetails>
                        <h1>
                          <TruncatedText text={name} limit={50} />
                        </h1>
                        <p>
                          <TruncatedText text={location} limit={50} />
                        </p>
                        <span>
                          {formatDateP(startDate)} - {formatDateP(endDate)}
                        </span>
                      </ResultItemDetails>
                    </MobileResultWrapper>
                  </MobileResultItem>
                )
              })
            ) : (
              <MobileResultItem $noResult>
                No events from your search.
              </MobileResultItem>
            )}
            <MobileHeader>Venues</MobileHeader>
            {venueData?.venues?.length > 0 ? (
              venueData.venues.map((venue, idx) => {
                const { venue_image: venueImage, id, name, slug } = venue

                const imageUrl = venueImage
                  ? genImagePath({
                      ownerType: 4,
                      ownerId: id,
                      name: venueImage.name
                    })
                  : 'https://cpworldgroup.com/wp-content/uploads/2021/01/placeholder.png'

                return (
                  <MobileResultItem key={idx}>
                    <MobileResultWrapper onClick={() => sendToVenue(slug)}>
                      <ResultItemThumbnail
                        $imageUrl={`${imageUrl}?height=300&auto=compression`}
                      />
                      <ResultItemDetails>
                        <h1>{name}</h1>
                      </ResultItemDetails>
                    </MobileResultWrapper>
                  </MobileResultItem>
                )
              })
            ) : (
              <MobileResultItem $noResult>
                No venues from your search.
              </MobileResultItem>
            )}
          </ResultItemWrapper>
        )) || (
          <NoResultWrapper>
            {searchTerm && 'No results from your search. Try different terms.'}
          </NoResultWrapper>
        )}
      </AutocompleteResults>
    )
  }

  return (
    <Wrapper ref={ref}>
      <AutocompleteWrapper>
        <SearchWrapper>
          <SearchContent onClick={() => setShowResults(true)}>
            <SearchInput
              placeholder={props.searchText ?? 'Search by performance, Arts Organization, etc.'}
              value={searchTerm}
              returnValueOnChange={false}
              onChange={handleSearchInput}
              onSearch={handleSearch}
              name="search"
              withButton={false}
              clear
              icon
              handleClear={handleClear}
            />
          </SearchContent>
        </SearchWrapper>
        {showResults && searchTerm && renderResults()}
      </AutocompleteWrapper>
    </Wrapper>
  )
}

export default SearchBox
