import { frontloadConnect } from 'react-frontload'
import LinesEllipsis from 'react-lines-ellipsis'
import { connectStoreon } from 'storeon/react'
import { useHistory } from 'react-router-dom'
import { updateQuery, getQuery } from 'utils'
import { Banner, Button, ArticleCard, Pagination, Select, Loader, SearchModal } from 'components'
import React, { useMemo, useCallback, useEffect, useState } from 'react'
import buttercms from 'buttercms'
import { useStoreon } from 'storeon/react'

import {
  ArticlesWrapper,
  ArticleContent,
  LatestArticle,
  SectionWrapper,
  AritcleHeader,
  AllCards
} from './styled'

const categories = [
  { label: 'Latest', value: 'latest' },
  { label: 'Artist Spotlight', value: 'artist-spotlight' },
  { label: 'Dance', value: 'dance' },
  { label: 'Jazz', value: 'jazz' },
  { label: 'Theater', value: 'theater' },
  { label: 'Opera', value: 'opera' },
  { label: 'Classical', value: 'classical' }
]

const fetchPosts = async ({ dispatch, category, page = 1 }) => {
  const butter = buttercms('dec2b11b1e450ccee111a5e3f225c6992437bb01')

  try {
    if (!category) {
      const allArticles = {}

      for (let i = 0; i < categories.length; i++) {
        const { value } = categories[i]

        const { data } = await butter.post.list({
          page: 1,
          page_size: 2,
          exclude_body: true,
          category_slug: value === 'latest' ? undefined : value
        })

        allArticles[value] = data.data
      }

      dispatch('setArticles', allArticles)
    } else {
      const { data } = await butter.post.list({
        page,
        page_size: 10,
        exclude_body: true,
        category_slug: category === 'latest' ? undefined : category
      })

      dispatch('setArticles', data)
    }
  } catch (e) {
    console.error(e.message)
  }
}

const Articles = ({ isMobile, articles, dispatch }) => {
  const {showSearchModal} = useStoreon('showSearchModal');

  const history = useHistory()
  const { c = '', page = 1 } = getQuery(history)

  const loading = useMemo(() => {
    if (articles) {
      return false;
    } else {
      return true;
    }
  }, [articles]);

  useEffect(() => {
    if (!articles) {
      fetchPosts({ dispatch, category: c, page })
    }
  }, [articles, c, dispatch, page])

  const handePageChange = useCallback(() => {
    dispatch('setArticles', null)
  }, [dispatch])

  const handleFilter = useCallback(
    (category) => {
      dispatch('setArticles', null)

      updateQuery({
        history,
        replace: true,
        newParams: { c: category }
      })
    },
    [history, dispatch]
  )

  const searchModal = useMemo(() => {
    if (showSearchModal) {
      return (
        <SearchModal />
      )            
    }
  }, [showSearchModal])

  const banner = useMemo(() => {
    if (!articles) {
      return null
    }

    const articlesArr = c ? articles?.data : articles?.latest

    if (!articlesArr) {
      return null
    }

    const {
      title,
      featured_image: featuredImage,
      summary,
      slug
    } = articlesArr[0]

    return (
      <Banner image={featuredImage} backgroundPosition="center top">
        <ArticleContent>
          <LatestArticle>
            <h2>{title}</h2>
            <LinesEllipsis text={summary} maxLine={2} ellipsis="..." />
            <Button to={`/articles/${slug}`}>Read more</Button>
          </LatestArticle>
        </ArticleContent>
      </Banner>
    )
  }, [articles, c])

  const renderSection = useCallback(
    ({ label, value }, idx) => {
      if (!articles) {
        return null
      }

      const sectionArticles = articles[value] || []

      return (
        <SectionWrapper key={idx}>
          <h2>
            <span>{label}</span>
            <a onClick={() => handleFilter(value)}>
              See more {label.toLowerCase()}
            </a>
          </h2>
          <div>
            <ArticleCard article={sectionArticles[0]} />
            {!isMobile && sectionArticles[1] && (
              <ArticleCard article={sectionArticles[1]} />
            )}
          </div>
        </SectionWrapper>
      )
    },
    [handleFilter, articles, isMobile]
  )

  const content = useMemo(() => {
    if (!articles) {
      return null
    }

    if (!c) {
      return categories.map(renderSection)
    }

    const metadata = {
      perPage: 10,
      total: articles.meta.count
    }

    return (
      <>
        <AllCards>
          {articles?.data?.map((a, idx) => (
            <ArticleCard key={idx} article={a} />
          ))}
        </AllCards>
        <Pagination key={page} metadata={metadata} onChange={handePageChange} />
      </>
    )
  }, [c, articles, page, renderSection, handePageChange])

  useEffect(() => {
    const restoreScrollPosition = () => {
      const scrollY = localStorage.getItem('scrollPosition') || 0
      window.scrollTo(0, parseInt(scrollY, 10))
    }

    restoreScrollPosition()
  }, [loading])

  if (loading) {
    return (
      <div style={{height: '100vh', margin: 'auto', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <p>Loading</p>
        <div style={{marginLeft: '10px'}}>
          <Loader/>
        </div>

      </div>
    )
  }

  return (
    <ArticlesWrapper key={page}>
      {banner}
      <AritcleHeader>
        <div />
        <Select
          placeholder="Filter by category"
          options={categories}
          value={c}
          onChange={(e) => handleFilter(e.value)}
        />
      </AritcleHeader>
      {content}
      {searchModal}
    </ArticlesWrapper>
  )
}

export default connectStoreon(
  'isMobile',
  'articles',
  frontloadConnect(async ({ dispatch, history }) => {
    const { c = '', page = 1 } = getQuery(history)

    await fetchPosts({ dispatch, category: c, page })
  })(Articles)
)
