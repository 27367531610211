import styled, { css } from 'styled-components'
import { colors, maxDesktopWidth, breakpoints } from 'constant'

const { md } = breakpoints

export const MainWrapper = styled.div``

export const BannerImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500px;
  background-image: url('https://red-curtain-addict.imgix.net/static/something-new.jpg');
  background-size: cover;
  background-position: center;
  /* overflow: hidden; */

  justify-content: center;

  ${md} {
    height: 320px;
    justify-content: flex-end;
  }
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 500px;
  background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for darkness */

  ${md} {
    height: 320px;
  }
`

export const TitleContainer = styled.div`
  position: relative;
  z-index: 1; /* Ensure the texts are above the overlay */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 20px 80px;
  box-sizing: border-box;
  color: white;
  align-items: center;

  ${md} {
    padding: 20px 20px;
    justify-content: center;
  }
`

export const SearchWrapper = styled.div`
  margin-bottom: 75px;
  max-width: 1136px;
  width: 100%;

  ${md} {
    margin-top: 75px;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const BannerTitle = styled.h1`
  > h1 {
    font-size: 48px;
    margin-bottom: 10px;
    text-align: center;

    ${md} {
      font-size: 32px;
    }
  }

  > p {
    width: 800px;
    font-size: 18px;
    font-weight: normal;
    text-align: center;

    ${md} {
      width: 100%;
      font-size: 13px;
    }
  }
`

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  ${md} {
    flex-direction: column;
  }
`

export const PreferenceCardWrapper = styled.div`
  display: flex;

  /* justify-content: space-between; */

  ${({ $isMobile }) =>
    $isMobile
      ? css`
          gap: 0px;
        `
      : css`
          gap: 8px;
        `}

  > div {
    ${({ $isMobile }) =>
      $isMobile
        ? css`
            width: calc(100% / 3 - 4px);
          `
        : css`
            width: calc(100% / 4 - 8px);
          `}
  }
`

export const EventCardWrapper = styled.div`
  display: flex;
  gap: 8px;
  /* justify-content: space-between; */

  > a {
    ${({ $isMobile }) =>
      $isMobile
        ? css`
            width: calc(100% / 3 - 4px);
          `
        : css`
            width: calc(100% / 4 - 8px);
          `}
  }
`

export const CarouselTitleWrapper = styled.div`
  padding: 10px 80px;
  margin-top: 24px;

  > h1 {
    font-size: 34px;
  }

  ${md} {
    padding: 0px 10px;

    > h1 {
      font-size: 24px;
    }
  }
`

export const CarouselWrapper = styled.div`
  padding-left: 80px;
  padding-right: 80px;
  margin-top: 20px;

  ${md} {
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const CarouselRow = styled.div`
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }
`

export const RcaLogo = styled.div`
  height: 48px;
  width: 64px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');
`

export const MobileFilters = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const MobileSection = styled.div`
  width: 100%;
  z-index: 5;

  > h3 {
    font-weight: bold;
    font-size: 23px;
    line-height: 33px;
    margin-bottom: 30px;
  }

  > div {
    display: flex;
    overflow: scroll;
    z-index: 5;
    margin: 0px -10px;

    > *:first-child {
      margin-left: 6px;
    }

    > *:last-child {
      margin-right: 6px;
    }

    > * {
      flex-shrink: 0;
      width: ${({ itemWidth }) => itemWidth || '280px'};
      margin-right: 6px;
      position: relative;
    }
  }
`
