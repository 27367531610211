import React, { useMemo, useState } from 'react'
import {
  EventsWrapper,
  EventItems,
  EventsHeader,
  BannerContent,
  SponsoredWrapper,
  SponsoredEvents,
  ThickRedLine,
  SponsoredItems
} from './styled'
import { useHistory, Link } from 'react-router-dom'
import { getQuery, genImagePath } from 'utils'
import { useQuery, gql } from '@apollo/client'
import { format } from 'date-fns'
import { useStoreon } from 'storeon/react'
import {
  EventCard,
  Pagination,
  MaxWidthContainer,
  EventSearchBar,
  Banner,
  Loader,
  SearchModal
} from 'components'

import NoResult from '../NoResult'

const GET_MAJOR_CITIES = gql`
  query cities {
    major_cities(order_by: { cid: asc }) {
      cid
      id
      image_name
      name
    }
  }
`

const GET_EVENTS = gql`
  query getevents($limit: Int!, $offset: Int!, $where: events_bool_exp = {}) {
    events(
      limit: $limit
      offset: $offset
      order_by: { start_date: asc }
      where: $where
    ) {
      id
      location
      end_date
      cover_image_id
      about
      major_city
      min_price
      name
      slug
      start_date
      event_images {
        id
        name
      }
      event_tags {
        tag {
          name
        }
      }
    }
  }
`
const GET_EVENTS_AGGREGATE = gql`
  query geteventsaggregate($where: events_bool_exp = {}) {
    events_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

const GET_SPONSORED_EVENTS = gql`
  query getsponsoredevents($where: sponsored_events_bool_exp = {}) {
    sponsored_events(where: $where) {
      eventId
      event {
        id
        location
        end_date
        cover_image_id
        about
        major_city
        min_price
        name
        slug
        start_date
        event_images {
          id
          name
        }
        event_tags {
          tag {
            name
          }
        }
      }
    }
  }
`

const perPage = 12

const eventArticles = {
  0: 'everything-you-need-to-know-about-the-performing-arts-in-san-francisco',
  1: 'nyc-guide-to-all-things-performing-arts',
  2: 'los-angeles-your-guide-to-the-performing-arts',
  3: 'guide-to-chicagos-performing-arts-scene',
  5: 'washington-d-c-and-maryland-performing-arts-city-guide',
  6: 'how-to-experience-londons-performing-arts-scene',
  12: '',
  13: ''
}

const Events = () => {
  const history = useHistory()
  const { isMobile, showSearchModal, dispatch } = useStoreon(
    'isMobile',
    'showSearchModal'
  )

  const {
    l = '',
    p = '',
    sd = null,
    ed = null,
    t = '',
    page = 1,
    q = ''
  } = getQuery(history)
  const startDate = sd ? format(new Date(sd * 1000), 'P') : undefined
  const endDate = ed ? format(new Date(ed * 1000), 'P') : startDate || undefined
  const now = format(new Date(), 'P')
  const tags = t ? t.split(',').filter((item) => !!item) : undefined
  const maxPrice = p || undefined
  let currCity

  const where = {
    name: {
      _ilike: `%${q}%`
    },
    min_price: maxPrice
      ? {
          _lte: maxPrice
        }
      : undefined,
    event_tags: tags
      ? {
          tag: {
            name: {
              _in: tags
            }
          }
        }
      : undefined,
    major_city: l
      ? {
          _in: [l]
        }
      : undefined,
    _or: startDate
      ? [
          {
            _and: [
              { start_date: { _lte: startDate } },
              { end_date: { _gte: startDate } }
            ]
          },
          {
            _and: [
              { start_date: { _lte: endDate } },
              { end_date: { _gte: endDate } }
            ]
          },
          {
            _and: [
              { start_date: { _gte: startDate } },
              { start_date: { _lte: endDate } }
            ]
          },
          {
            _and: [
              { end_date: { _gte: startDate } },
              { end_date: { _lte: endDate } }
            ]
          }
        ]
      : [
          {
            _and: [{ end_date: { _gte: format(new Date(), 'P') } }]
          }
        ]
  }

  const { loading: sponsoredLoading, data: sponsoredData } = useQuery(
    GET_SPONSORED_EVENTS,
    {
      variables: {
        where: {
          start_date: {
            _lte: now
          },
          end_date: {
            _gte: now
          },
          event: {
            major_city: {
              _in: [l]
            },
            _and: startDate
              ? [
                  {
                    _and: [
                      { start_date: { _lte: startDate } },
                      { end_date: { _gte: startDate } }
                    ]
                  },
                  {
                    _and: [
                      { start_date: { _lte: endDate } },
                      { end_date: { _gte: endDate } }
                    ]
                  },
                  {
                    _and: [
                      { start_date: { _gte: startDate } },
                      { start_date: { _lte: endDate } }
                    ]
                  },
                  {
                    _and: [
                      { end_date: { _gte: startDate } },
                      { end_date: { _lte: endDate } }
                    ]
                  }
                ]
              : [
                  {
                    _and: [{ end_date: { _gte: format(new Date(), 'P') } }]
                  }
                ]
          }
        }
      }
    }
  )
  const { loading: majorCitiesLoading, data: majorCitiesData } =
    useQuery(GET_MAJOR_CITIES)
  const { loading: eventsLoading, data: eventsData } = useQuery(GET_EVENTS, {
    variables: {
      limit: perPage,
      offset: (page - 1) * perPage,
      where: where
    }
  })
  const { loading: eventsAggLoading, data: eventsAggData } = useQuery(
    GET_EVENTS_AGGREGATE,
    {
      variables: {
        where: where
      }
    }
  )

  const cityMap = {}
  const majorCities = majorCitiesData?.major_cities
  const events = eventsData?.events
  const eventsAgg = eventsAggData?.events_aggregate
  const sponsored = sponsoredData?.sponsored_events.map((item) => item.event)

  const loading = useMemo(() => {
    if (majorCitiesLoading || eventsLoading || eventsAggLoading) {
      return true
    } else if (!majorCities || !events || !eventsAgg) {
      return true
    }

    if (l) {
      if (sponsoredLoading) {
        return true
      }
    }

    return false
  }, [
    majorCitiesLoading,
    eventsLoading,
    eventsAggLoading,
    majorCities,
    events,
    eventsAgg,
    sponsoredLoading
  ])

  majorCities?.forEach((i) => {
    cityMap[i.cid] = {
      id: i.id,
      imageName: i.image_name,
      cid: i.cid,
      name: i.name
    }
  })

  if (l !== undefined) {
    currCity = cityMap[l]
  }

  const total = eventsAgg?.aggregate.count || 0

  const metadata = { perPage, total }

  const withResult = useMemo(
    () => (!eventsLoading && events?.length < 1 ? false : true),
    [eventsLoading, events]
  )

  const searchModal = useMemo(() => {
    if (showSearchModal) {
      return (
        <SearchModal />
      )            
    }
  }, [showSearchModal])

  const renderEvent = (item, idx) => {
    return <EventCard key={idx} data={item} />
  }

  const renderBanner = () => {
    if (currCity) {
      const { name, id, cid, imageName } = currCity
      const imageUrl = genImagePath({
        ownerType: 2,
        ownerId: id,
        name: imageName
      })

      const articleLink = eventArticles[cid]

      return (
        <Banner image={imageUrl}>
          <MaxWidthContainer>
            <BannerContent>
              <h2>{name}</h2>
              <p>
                Check out our recommended destinations for {name}!
                {articleLink && (
                  <Link to={`/articles/${articleLink}`}>Read more here!</Link>
                )}
              </p>
              <EventSearchBar reroute />
            </BannerContent>
          </MaxWidthContainer>
        </Banner>
      )
    }

    return (
      <EventsHeader>
        <MaxWidthContainer>
          <EventSearchBar reroute />
        </MaxWidthContainer>
      </EventsHeader>
    )
  }

  if (loading) {
    return (
      <div
        style={{
          height: '100vh',
          margin: 'auto',
          fontSize: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Loading</p>
        <div style={{ marginLeft: '10px' }}>
          <Loader />
        </div>
      </div>
    )
  }

  if (!withResult) {
    return <NoResult />
  }

  return (
    <EventsWrapper currCity={currCity}>
      {renderBanner()}
      {l && sponsored.length >= 1 && (
        <SponsoredEvents>
          <SponsoredWrapper>
            <h2>Featured Events</h2>
            <ThickRedLine />
            <SponsoredItems>
              <div>{sponsored?.map(renderEvent)}</div>
            </SponsoredItems>
          </SponsoredWrapper>
        </SponsoredEvents>
      )}
      <EventItems>
        <div>{events?.map(renderEvent)}</div>
      </EventItems>
      <MaxWidthContainer>
        <Pagination metadata={metadata} />
      </MaxWidthContainer>
      {searchModal}
    </EventsWrapper>
  )
}

export default Events
