import React, { useState, useEffect, useMemo } from 'react'

import {
  BannerImageWrapper,
  MainWrapper,
  BannerTitle,
  Overlay,
  TitleContainer,
  PreferenceCardWrapper,
  CarouselWrapper,
  CarouselRow,
  SearchWrapper,
  MobileSection,
  BannerContent,
  LocationLabelWrapper,
  LocationWrapper,
  SelectedCityWrapper,
  LocationStyling
} from './styled'

import {
  Carousel,
  Loader,
  EventSearchBar,
  PreferenceCard,
  SearchModal,
  Dropdown,
  Icon
} from 'components'
import { useQuery, useLazyQuery, gql } from '@apollo/client'
import { useStoreon } from 'storeon/react'
import { topics, majorCities } from 'constant'
import { capitalizeFirstLetter } from 'utils'

const GET_ORGS = gql`
  query getvenues($where: venues_bool_exp = {}) {
    venues(where: $where) {
      id
      name
      slug
      venue_tags {
        tag {
          name
        }
        tag_id
      }
      venue_image {
        name
      }
      user_followed_venues {
        user_id
      }
    }
  }
`

const GET_USER = gql`
  query users($uid: String!) {
    users(where: { uid: { _eq: $uid } }) {
      id
    }
  }
`

const SomethingNew = () => {
  const [followedItems, setFollowedItems] = useState([])
  const [sortedChunkedArtOrgs, setSortedChunkedArtOrgs] = useState([])
  const [city, setCity] = useState(null)
  // const [sortingOrgs, setSortingOrgs] = useState(true)
  // const [sortedOrgs, setSortedOrgs] = useState()
  const { user, loading, isMobile, showSearchModal, userData } =
    useStoreon('user', 'loading', 'isMobile', 'showSearchModal', 'userData')
  const userCityIds = userData?.cities?.map((id) => id + 1) || []
  const [getUserId, { data: userIdData }] = useLazyQuery(GET_USER)
  const [getOrgs, { loading: orgsLoading, data: orgsData }] = useLazyQuery(GET_ORGS)
  // const [
  //   getOrgsFromOtherCities,
  //   { loading: otherCityOrgsLoading, data: otherCityOrgsData }
  // ] = useLazyQuery(GET_ORGS, {
  //   variables: {
  //     where: {
  //       _or: [
  //         { major_city_id: { _nin: userCityIds } },
  //         { major_city_id: { _is_null: true } }
  //       ]
  //     }
  //   }
  // })

  useEffect(() => {
    getOrgs({
      variables: {
        where: {
          major_city_id: city ? {_eq: (+city + 1)} : userCityIds.length > 0 ? { _in: userCityIds } : {_eq: 3}
        }
      }
    })
  }, [city, userData])

  // useEffect(() => {
  //   if (userData && userData.cities && userData.cities.length >= 1) {
  //     getOrgsFromOtherCities()
  //   }
  // }, [userData])

  // useEffect(() => {
  //   if (orgsData && otherCityOrgsData) {
  //     setSortedOrgs([...orgsData.venues, ...otherCityOrgsData.venues])

  //     setSortingOrgs(false)
  //   }
  // }, [orgsData, otherCityOrgsData])

  useEffect(() => {
    if (orgsData) {
      const artOrgArrayByTag = []

      topics.map((item) => {
        const filtered = orgsData.venues.filter((v) =>
          v.venue_tags.some((vt) => vt.tag_id === Number(item.value))
        )

        const chunkSize = 4
        const tempChunks = []
        for (let i = 0; i < filtered?.length; i += chunkSize) {
          tempChunks.push(filtered.slice(i, i + chunkSize))
        }

        artOrgArrayByTag.push({
          label: capitalizeFirstLetter(item.label),
          artOrgChunks: tempChunks
        })
      })

      setSortedChunkedArtOrgs(artOrgArrayByTag)
    }
  }, [orgsData])

  const dataLoading = useMemo(() => {
    if (loading || orgsLoading) {
      return true
    }

    if (!orgsData) {
      return true
    }

    return false
  }, [loading, orgsLoading, orgsData])

  const handleFollowClick = (venue_id, action) => {
    if (action) {
      setFollowedItems((prevItems) => [...prevItems, venue_id])
    } else {
      setFollowedItems((prevItems) => prevItems.filter((id) => id !== venue_id))
    }
  }

  const locationDropdown = useMemo(() => {
    const dropdownMenu = majorCities.map((loc) => ({
      label: loc.label,
      onClick: () => setCity(loc.value)
    }))

    if (city) {
      dropdownMenu.unshift({
        label: 'Anywhere!',
        onClick: () => setCity(null)
      })
    }

    const selectedCity = city
      ? majorCities.find((mc) => mc.value === city)?.label
      : null

    return (
      <LocationStyling>
        <Dropdown menu={dropdownMenu} extraText>
          <LocationWrapper>
            {selectedCity ? (
              <SelectedCityWrapper>{selectedCity}</SelectedCityWrapper>
            ) : (
              <LocationLabelWrapper>
                <span>Location</span> <Icon name="dropdown" />
              </LocationLabelWrapper>
            )}
          </LocationWrapper>
        </Dropdown>
      </LocationStyling>
    )
  }, [city])

  const renderVenue = (item, idx) => {
    let isFollowed = followedItems.includes(item.id)

    return (
      <PreferenceCard
        id={item.id}
        userId={userIdData ? userIdData.users[0].id : ''}
        followed={isFollowed}
        followAllowed={true}
        key={idx}
        ownerId={item.id}
        ownerType={4}
        name={item.venue_image?.name || ''}
        label={item.name}
        actionUrl={'/venue/' + item.slug}
        onFollow={(res) => handleFollowClick(item.id, res)}
      />
    )
  }

  useEffect(() => {
    if (user?.uid) {
      getUserId({
        variables: {
          uid: user?.uid
        }
      })
    }
  }, [user])

  useEffect(() => {
    if (userIdData && orgsData) {
      const newFollowedItems = []

      topics.forEach((tagItem, idx) => {
        const filteredorgs = orgsData.venues.filter((venue) =>
          venue.venue_tags.some((vt) => vt.tag_id === Number(tagItem.value))
        )

        filteredorgs.forEach((filteredItem) => {
          if (filteredItem && userIdData) {
            const userFollowedVenue = filteredItem.user_followed_venues.find(
              (v) => v.user_id === userIdData.users[0]?.id
            )
            if (userFollowedVenue && !followedItems.includes(filteredItem.id)) {
              newFollowedItems.push(filteredItem.id)
            }
          }
        })
      })

      setFollowedItems([...followedItems, ...newFollowedItems])
    }
  }, [userIdData, orgsData])

  const searchModal = useMemo(() => {
    if (showSearchModal) {
      return (
        <SearchModal />
      )            
    }
  }, [showSearchModal])

  useEffect(() => {
    const restoreScrollPosition = () => {
      const scrollY = localStorage.getItem('scrollPosition') || 0
      window.scrollTo(0, parseInt(scrollY, 10))
    }

    restoreScrollPosition()
  }, [dataLoading])

  if (dataLoading) {
    return (
      <div
        style={{
          height: '100vh',
          margin: 'auto',
          fontSize: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Loading</p>
        <div style={{ marginLeft: '10px' }}>
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <MainWrapper>
      <BannerImageWrapper>
        <Overlay />
        <BannerContent>
          {/* <SearchWrapper>
            <EventSearchBar reroute searchText="Search for Art Orgs" />
          </SearchWrapper> */}
          <TitleContainer>
            <BannerTitle>
              <h1>Follow Arts Organizations You Love!</h1>
              <p>
                Your selections will appear on your homepage so you can easily see
                what performances they are presenting at a given time!
              </p>
            </BannerTitle>
          </TitleContainer>
        </BannerContent>
        {locationDropdown}
      </BannerImageWrapper>
      <CarouselWrapper>
        {orgsData &&
          !isMobile &&
          sortedChunkedArtOrgs.map((item, idx) => {
            {
              if (item.artOrgChunks.length > 0) {
                return (
                  <CarouselRow key={idx}>
                    <Carousel label={capitalizeFirstLetter(item.label)}>
                      {item.artOrgChunks.map((chunk) => {
                        return (
                          <PreferenceCardWrapper>
                            {chunk.map(renderVenue)}
                          </PreferenceCardWrapper>
                        )
                      })}
                    </Carousel>
                  </CarouselRow>
                )
              } else {
                return (
                  <MobileSection itemWidth={'44vw'}>
                    <h3>{item.label}</h3>
                    <p>No arts organization currently listed but more coming online soon!</p>
                  </MobileSection>
                )
              }
            }
          })}

        {/* IF MOBILE */}
        {orgsData &&
          isMobile &&
          sortedChunkedArtOrgs.map((item, idx) => {
            {
              return (
                <MobileSection itemWidth={'44vw'}>
                  <h3>{item.label}</h3>
                  {item.artOrgChunks.length > 0 ? (
                    <div>{item.artOrgChunks.flat().map(renderVenue)}</div>
                  ) : (
                    <p>No arts organization currently listed but more coming online soon!</p>
                  )}
                </MobileSection>
              )
            }
          })}
      </CarouselWrapper>

      {searchModal}
    </MainWrapper>
  )
}

export default SomethingNew
