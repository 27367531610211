import styled, { css } from 'styled-components'
import { colors, maxDesktopWidth, breakpoints } from 'constant'

const { md } = breakpoints

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${colors.c5};
  background: ${colors.c6};
`

export const BannerWrapper = styled.div`
  height: 600px;
  width: 100%;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  display: flex;

  &:before {
    content: '';
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0)
    );
    position: absolute;
    height: 100px;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:after {
    content: '';
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0)
    );
    position: absolute;
    height: 80px;
    right: 0;
    top: 0;
    left: 0;
  }

  ${({ isMobile }) =>
    isMobile &&
    `
      height: 290px;
    `
  }
`

export const BannerContentBg = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
`

const bannerContentMobile = css`
  flex-wrap: wrap;
  padding: 16px;
  align-items: center;
  align-content: flex-end;
  justify-content: center;
  width: 100%;
`

export const BannerContent = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  padding: 12px 32px 40px 32px;
  justify-content: space-between;
  padding-bottom: 110px;

  ${({ isMobile }) =>
    !isMobile &&
    `
      width: ${maxDesktopWidth};
      max-width: 100%;
    `
  }

  ${({ isMobile }) => isMobile && bannerContentMobile}
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;

  > h1 {
    display: flex;
    font-family: 'Bebas Neue';
    font-size: 64px;
    letter-spacing: 2px;

    > div {
      text-wrap: none;

      > svg {
        width: 24px;
      }
    }

    .check-tooltip {
      background: ${colors.c18};
      font-size: 14px;
      font-family: 'Open Sans';
      font-weight: 400;
      letter-spacing: 0px;
      text-wrap: wrap;
      width: 325px;
      border-radius: 24px;
      border: 1px solid black;
    }
    .check-tooltip .react-tooltip-arrow {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
  }

  > h4 {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 500;
  }

  > button {
    display: flex;
    align-items: center;
    background: ${colors.buttonBackground};
    color: white;
    border-radius: 24px;
    padding: 10px 32px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
  }

  ${md} {
    width: 100%;

    > button {
      > svg {
        max-width: 24px;
        max-height: 24px;
        margin-right: 10px;
      }
    }

    > h1 {
      font-size: 24px;

      > svg {
        max-width: 12px;
        margin-bottom: 12px;
      }
    }
  }
`

export const Right = styled.div`
  > button {
    display: flex;
    align-items: center;
    background: ${colors.buttonBackground};
    color: white;
    border-radius: 24px;
    padding: 10px 32px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    width: 100%;

    > svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }

  .follow-tooltip {
    background: ${colors.c18};
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 400;
    letter-spacing: 0px;
    text-wrap: wrap;
    width: 325px;
    border-radius: 24px;
    z-index: 9999;
    border: 1px solid black;
  }
  .follow-tooltip .react-tooltip-arrow {
    border-right: 1px solid black;
    border-bottom: 1px solid black;
  }

  ${md} {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    > button {
      font-size: 14px;
      padding: 12px 20px;

      > svg {
        height: 18px;
        width: 18px;
        margin-right: 8px;
      }
    }
  }
`

export const TagItem = styled.div`
  text-transform: capitalize;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.c3};
  border: 1px solid ${colors.c2};
  background: rgba(194, 32, 38, 0.16);
  border-radius: 50px;
  backdrop-filter: blur(16px);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 24px;

  ${md} {
    font-size: 12px;
    padding: 8px 14px;
  }
`

export const TagWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  text-transform: capitalize;

  > div {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  ${md} {
    flex-wrap: wrap;

    > div {
      margin-bottom: 8px;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
`

export const Content = styled.div`
  max-width: 100%;
  width: ${maxDesktopWidth};
  padding: 32px;

  ${md} {
    padding: 16px;
  }
`

export const Details = styled.div`
  display: flex;
  transform: translateY(-10px);
  flex-direction: column;
`
export const DetailsTop = styled.div`
  display: flex;
  flex-direction: row;

  ${md} {
    flex-direction: column;
  }
`

export const DetailsLeft = styled.div`
  flex: 1;
  padding-right: 36px;

  > h1 {
    font-size: 24px;
  }

  > * {
    margin-bottom: 12px;
  }

  > p {
    margin-bottom: 32px;
  }

  ${md} {
    padding-right: 0px;
  }
`

export const AboutWrapper = styled.div`
  margin-bottom: 24px;
`

export const DetailsRight = styled.div`
  width: 380px;
  flex-shrink: 0;
  max-width: 100%;

  > div {
    &:first-child {
      margin-bottom: 24px;
    }
  }

  ${md} {
    width: 100%;
  }
`

export const RoundInfo = styled.div`
  border-radius: 24px;
  overflow: hidden;
  background: ${colors.c18};
  border: 2px solid black;
`

export const UsefulInfo = styled.div`
  > * {
    &:not(:last-child) {
      border-bottom: 2px solid black;
    }
  }
`

export const InfoItem = styled.div`
  padding: 17px 24px;
  display: flex;
  align-items: center;

  > svg {
    height: 24px;
    width: 24px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  > a {
    color: white;
  }

  ${md} {
    padding: 12px 24px;
    font-size: 14px;

    > svg {
      height: 20px;
      width: 20px;
      margin-right: 14px;
    }
  }
`

export const InfoItemEnd = styled.div`
  padding: 24px;
  font-size: 24px;

  ${md} {
    padding: 12px 24px;
    font-size: 14px;
  }
`

export const SocialLink = styled.a`
  background: ${colors.c1};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;

  > svg {
    height: 22px;
    width: 22px;
    color: white;
  }
`

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;

  > a {
    margin-right: 16px;
  }
`

export const EventsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 48px;
  flex-wrap: wrap;
`

export const FeaturedImage = styled.div`
  width: 100%;
  height: 425px;
  border-radius: 24px;
  background-image: url(${({ image }) => image});
  background-size: cover;
  margin-right: 30px;

  ${md} {
    height: 250px;
  }
`

export const VenueEventWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const VenueFlex = styled.div`
  display: flex;
  flex-direction: row;
  background: ${colors.background};

  > button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.buttonBackground};
    color: white;
    border-radius: 24px;
    padding: 10px 12px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    height: 30px;
    width: 220px;
    margin-left: 10px;

    > div {
      width: unset;
      position: unset;
      
      > div {
        > div {
          font-size: 16px;
          padding: 0px;
          border-right: none;
          border-left: none;
        }
      }
    } 
    
    ${md} {
      width: 50%;
    }
  }

  > svg {
    height: 24px;
    width: 24px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  > a {
    color: white;
  }

  ${md} {
    padding: 12px 0px;
    font-size: 14px;

    > svg {
      height: 20px;
      width: 20px;
      margin-right: 14px;
    }
  }
`

export const EventItems = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 24px;
  width: 100%;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(96, 96, 96, 0.8);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  > div {
    width: ${maxDesktopWidth};
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;

    > div {
      flex: 0 0 25%;
    }

    > a {
      margin: 8px;
      min-width: calc((100% / 4) - 16px);
      max-width: calc((100% / 4) - 16px);

      ${md} {
        min-width: 85%;
        margin: 12px 8px;
      }
    }
  }
`

export const NoEvents = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  text-align: center;
`
export const MobileFilters = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const RcaLogo = styled.div`
  height: 48px;
  width: 64px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://red-curtain-addict.imgix.net/static/rcafit.png?height=300&auto=compression');
`

export const ClaimOrg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    margin-bottom: 10px;
  }

  > button {
    display: flex;
    align-items: center;
    background: ${colors.buttonBackground};
    color: white;
    border-radius: 24px;
    padding: 10px 32px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    width: 240px;

    > svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }

    ${md} {
      max-width: 70%;
    }
  }
`